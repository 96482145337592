<template>
  <a-card>
    <a-form-model ref="searchForm" :model="searchForm" class="search-form" v-bind="layout">
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="经销商名称">
            <a-input placeholder="请输入" v-model.trim="searchForm.dealer_name"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="tabs== 'learning'">
          <a-form-model-item label="风火轮帐号">
            <a-input placeholder="请输入" v-model.trim="searchForm.account"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="tabs == 'module'">
          <a-form-model-item label="统计平台">
            <a-select v-model="searchForm.platform_type">
              <a-select-option :value="1">抖音</a-select-option>
              <a-select-option :value="2">快手</a-select-option>
              <a-select-option :value="3">懂车帝</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="tabs == 'module'">
          <a-form-model-item label="统计时间">
            <a-range-picker
                :placeholder="['开始时间', '结束时间']"
                :allowClear="false"
                show-time
                format="YYYY-MM-DD"
                inputReadOnly
                @change="onChangeDate"
                style="width:100%"
                v-model="searchForm.time"
            ></a-range-picker>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="tabs== 'learning'">
          <a-form-model-item label="使用人">
            <a-input placeholder="请输入" v-model.trim="searchForm.user_name"/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8" v-if="tabs== 'learning'">
          <a-form-model-item label="联系电话">
            <a-input placeholder="请输入" v-model.trim="searchForm.mobile"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="经销商分组">
            <a-select
                v-model="searchForm.account_group_id"
                show-search
                allow-clear
                option-filter-prop="children"
                :filter-option="null"
                @search="selectGroupName"
                :default-active-first-option="false"
                :not-found-content="isFollowLoading ? undefined : null"
            >
              <a-select-option v-for="item in groupList" :key="item.group_id" :value="item.group_id">
                {{ item.group_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="tabs == 'module'">
        </a-col>
        <a-col :span="8">
          <div class="flex-wrap">
            <a-button class="ml-10p" type="primary" @click="searchClick">搜索</a-button>
            <a-button class="ml-10p" type="dashed" @click="resetSearch">重置</a-button>
          </div>
        </a-col>
      </a-row>
    </a-form-model>
    <a-table
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :rowKey="(record, index) => index"
        :loading="isLoading"
        class="mt-40p"
    >
      <div slot="dealerInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>经销商名称：</span></div>
          <div>{{ record.dealer_name || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>品牌：</span></div>
          <div>{{ record.principal_names || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>大区/省/市：</span></div>
          <div>{{ record.area_name || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>分组：</span></div>
          <div>{{ record.account_group_names || "--" }}</div>
        </div>
      </div>
      <div slot="accountInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>帐号：</span></div>
          <div>{{ record.account || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>使用人：</span></div>
          <div>{{ record.user_name || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>联系手机：</span></div>
          <div>{{ record.mobile || "--" }}</div>
        </div>
      </div>
      <div slot="sectionsInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>总课程：</span></div>
          <div>{{ record.total_sections || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>已完成：</span></div>
          <div>{{ record.finish_sections || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>未完成：</span></div>
          <div>{{ record.un_finish_sections || "0" }}</div>
        </div>
      </div>
      <div slot="medalInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>勋章总数：</span></div>
          <div>{{ record.medal_count || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>已获取：</span></div>
          <div>{{ record.awarded_medal_count || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>未获取：</span></div>
          <div>{{ record.un_awarded_medal_count || "0" }}</div>
        </div>
      </div>
      <div slot="statisticalInfo" slot-scope="text, record" v-if="tabs == 'module'">
        <div class="row-box box-1">
          <div><span>统计平台：</span></div>
          <div>{{ record.platform_name || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>统计时间：</span></div>
          <div>{{ record.time || "0" }}</div>
        </div>
      </div>
      <div slot="moduleInfo" slot-scope="text, record" v-if="tabs == 'module'">
        <div class="row-box box-1">
          <div><span>使用模板：</span></div>
          <div>{{ record.template_total || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>生成成片：</span></div>
          <div>{{ record.aweme_total || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>发布视频：</span></div>
          <div>{{ record.publish_total || "0" }}</div>
        </div>
      </div>
      <div slot="moduleTypeInfo" slot-scope="text, record" v-if="tabs == 'module'">
        <div class="row-box box-1">
          <div><span>视频播放次数：</span></div>
          <div>{{ record.play_volume || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>视频点赞数：</span></div>
          <div>{{ record.digg_count || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>视频分享数：</span></div>
          <div>{{ record.share_count || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>视频评论数：</span></div>
          <div>{{ record.comment_count || "0" }}</div>
        </div>
      </div>
      <div slot="awardedInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <!-- <div class="row-box box-1">
             <div><span>勋章总数：</span></div>
             <div>{{ record.medal_count || "&#45;&#45;" }}</div>
           </div>-->
          <div><span>已获取：</span></div>
          <div>{{ record.awarded_certificate_count || "0" }}</div>
        </div>
      </div>
      <div slot="operation" slot-scope="text, record">
        <a style="display:block" @click="() => goDetail(record)">详情</a>
      </div>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.page_size"
        :total="pagination.total"
        @onChange="onChange"
        @onShowSizeChange="onChange"
    >
    </base-pagination>
  </a-card>
</template>

<script>
import https from "@/api/kpi.js";
import moment from "moment";


const debounce = (function() {
  let timer = 0;
  return function(callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

export default {
  name: "tab_list",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => [
        {
          dataIndex: "",
          scopedSlots: {customRender: "dealerInfo"},
          title: "经销商信息"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "accountInfo"},
          title: "风火轮帐号信息"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "sectionsInfo"},
          title: "课程学习进度"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "medalInfo"},
          title: "勋章获取情况"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "awardedInfo"},
          title: "认证情况"
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: {customRender: "operation"},
          width: 150
        }
      ]
    },
    activityId: {
      type: [String, Number],
    },
    principalId: {
      type: [String, Number],
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          total: 0,
          current: 1,
          page_size: 10,
        };
      }
    },
    tabs: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isLoading: {
      handler: function(val) {
        this.loading = val;
      },
      immediate: true
    },
  },
  data() {
    return {
      layout: {
        labelCol: {span: 6},
        wrapperCol: {span: 16}
      },
      searchForm: {
        time: [],
        start_time: "",
        end_time: ""
      },
      loading: false,
      isFollowLoading: false,
      groupList: []
    };
  },
  mounted() {
    this.$set(this.searchForm, "time", [moment().subtract(6, "days").format("YYYY-MM-DD") + " 00:00:00", moment().format("YYYY-MM-DD") + " 23:59:59"]);
    this.$set(this.searchForm, "end_time", moment().format("YYYY-MM-DD") + " 23:59:59");
    this.$set(this.searchForm, "start_time", moment().subtract(6, "days").format("YYYY-MM-DD") + " 00:00:00");
    this.getGroupSelect();
  },
  computed: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  },
  methods: {
    // 搜索
    searchClick() {
      this.$emit("searchTabList", Object.assign(this.searchForm, {
        current: this.pagination.current,
        page_size: this.pagination.page_size
      }), this.tabs);
    },
    // 重置选择条件
    resetSearch() {
      this.searchForm = {};
      this.$set(this.searchForm, "time", [moment().subtract(6, "days").format("YYYY-MM-DD") + " 00:00:00", moment().format("YYYY-MM-DD") + " 23:59:59"]);
      this.$set(this.searchForm, "end_time", moment().format("YYYY-MM-DD") + " 23:59:59");
      this.$set(this.searchForm, "start_time", moment().subtract(6, "days").format("YYYY-MM-DD") + " 00:00:00");
      this.$emit("searchTabList", this.searchForm, this.tabs);
    },
    onChange(current, pageSize) {
      this.$emit("searchTabList", Object.assign(this.searchForm, {current: current, page_size: pageSize}), this.tabs);
    },
    goDetail(record) {
      switch(this.tabs) {
        case "learning":
          this.$router.push({
            name: "dealer-detail",
            query: {
              dealer_id: record.dealer_id,
            }
          });
          break;
        case "module":
          this.$router.push({
            name: "template-detail",
            query: {
              dealer_id: record.dealer_id,
              platform_type: this.searchForm.platform_type,
              start_time: this.searchForm.start_time,
              end_time: this.searchForm.end_time,
            }
          });
          break;
      }
    },
    selectGroupName(value) {
      debounce(() => {
        if(value) {
          this.isFollowLoading = true;
          this.group_name = value;
          this.getGroupSelect();
        } else {
          this.isFollowLoading = false;
        }
      }, 500);
    },
    getGroupSelect() {
      https.getGroupSelect({group_name: this.group_name || ""}).then(res => {
        if(res.code === 200) {
          this.groupList = res.data || [];
        } else {
          this.groupList = [];
          this.$message.error(res.message || "获取公共分组失败");
        }
      });
    },
    //日期选择框
    onChangeDate(dates) {
      let start_time = "";
      let end_time = "";
      if(dates[0]) {
        start_time = this.$moment(dates[0]._d).format("YYYY-MM-DD") + " 00:00:00";
        end_time = this.$moment(dates[1]._d).format("YYYY-MM-DD") + " 23:59:59";
      }
      this.searchForm.end_time = end_time;
      this.searchForm.start_time = start_time;
    },
  }
};
</script>

<style scoped lang="less">
.row-box {
  display: flex;
  margin-bottom: 5px;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: bold;

    .title {
      display: flex;
      justify-content: space-between;
    }
  }

  & > div:last-child {
    flex: 1;
    min-width: 60px;
  }
}

.box-1 {
  & > div:first-child {
    width: 100px;
  }
}

.order-madel {
  display: flex;
  justify-content: flex-end;

}

.flex-wrap {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.ml-10p {
  margin-left: 10px;
}

</style>
