<template>
  <div>
    <div class="top-wrap">
      <div class="title">{{ activity_name }}</div>
      <div class="time">
        <a-button type="link" @click="handleOpen(1)">配置导出模板</a-button>
        <a-button type="primary" @click="handleOpen(2)">全部数据导出</a-button>
      </div>
    </div>
    <a-tabs @change="onChangeTabs">
      <a-tab-pane key="author" tab="帐号总览">
        <tabPageModal
            ref="authorTab"
            :timeLabel="'更新时间'"
            tabs="author"
            :groupList="groupList"
            :columns="columnsAccount"
            :dataList="dataList"
            :countData="countData"
            :isLoading="isLoading"
            :activityName="activity_name"
            @resetList="resetList"
            @searchList="searchList"
            @exportExcel="exportExcel"
            @onChange="onChange"
        ></tabPageModal>
      </a-tab-pane>
      <a-tab-pane key="live" tab="直播" force-render>
        <tabPageModal
            ref="liveTab"
            tabs="live"
            :timeLabel="'开播时间'"
            :groupList="groupList"
            :columns="columnsLive"
            :pagination="pagination"
            :dataList="dataList"
            :countData="countData"
            :isLoading="isLoading"
            :activityName="activity_name"
            @resetList="resetList"
            @searchList="searchList"
            @exportExcel="exportExcel"
            @onChange="onChange"
        ></tabPageModal>
      </a-tab-pane>
      <a-tab-pane key="video" tab="视频" force-render>
        <tabPageModal
            ref="videoTab"
            tabs="video"
            :timeLabel="'发布时间'"
            :groupList="groupList"
            :columns="columnsVideo"
            :dataList="dataList"
            :countData="countData"
            :isLoading="isLoading"
            :pagination="pagination"
            :activityName="activity_name"
            @resetList="resetList"
            @searchList="searchList"
            @exportExcel="exportExcel"
            @onChange="onChange"
        ></tabPageModal>
      </a-tab-pane>
      <a-tab-pane key="KPI" tab="KPI详情">
        <!-- <kpiPage
             ref="KPITab"
             tabs="KPI"
             :activityList="activityList"
             :dealersList="dealersList"
             :dealersData="dealersData"
         ></kpiPage>-->
        <kpiPage
            ref="KPITab"
            tabs="KPI"
            :list="KPIList"
            :pagination="pagination"
            :activityId="activity_id"
            :principalId="principal_id"
            :isLoading="isLoading"
            @searchKPIList="searchKPIList"
            @resetKPIList="resetKPIList"
            @onChange="onChangePaginationKPIList"
        ></kpiPage>
      </a-tab-pane>
      <a-tab-pane key="learning" tab="学情">
        <tabList
            ref="learningTab"
            :tabs="'learning'"
            :list="TabList"
            :columns="TabColumns"
            :pagination="pagination"
            :activityId="activity_id"
            :principalId="principal_id"
            :isLoading="isLoading"
            @searchTabList="searchTabList"
        ></tabList>
      </a-tab-pane>
      <a-tab-pane key="module" tab="模板">
        <tabList
            ref="moduleTab"
            :tabs="'module'"
            :list="TabList"
            :columns="moduleTabColumns"
            :pagination="pagination"
            :activityId="activity_id"
            :principalId="principal_id"
            :isLoading="isLoading"
            @searchTabList="searchTabList"
        ></tabList>
      </a-tab-pane>
    </a-tabs>

    <a-modal
        :visible="visible"
        title="全部数据导出"
        :width="600"
        :maskClosable="false"
        @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button
            @click="handleCancel"
        >取消
        </a-button>
        <a-button
            type="primary"
            :loading="is_submit"
            :disabled="is_submit"
            @click="exportExcelAll"
        >联合导出
        </a-button>
      </template>
      <a-form-model
          ref="dataForm"
          :model="dataForm"
          :rules="rules"
          v-bind="data_layout"
      >
        <a-form-model-item
            label="选择时间"
            prop="time"
        >
          <a-range-picker
              :placeholder="['开始时间', '结束时间']"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              inputReadOnly
              @change="onChangeDate"
              style="width:100%"
              v-model="dataForm.time"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import tabPageModal from "./components/tabPage";
import kpiPage from "./components/kpi_list";
import tabList from "./components/tab_list";
import moment from "moment";
import uploadExcel from "@/utils/uploadExcel.js";
import buryEvent from "@/utils/buryEvent.js";
import https from "@/api/kpi.js";
import activityApi from '@/api/activity'

export default {
  components: {
    tabPageModal,
    kpiPage,
    tabList
  },
  data() {
    return {
      activity_name: this.$route.query.activity_name || "",
      activity_id: this.$route.query.activity_id || "",
      principal_id: this.$route.query.principal_id || "",
      columnsAccount: [
        {
          scopedSlots: {customRender: "dataIndex"},
          title: "序号",
          width: 60
        },
        {
          dataIndex: "platform",
          scopedSlots: {customRender: "authorInfo"},
          title: "主播数据"
        },
        {
          dataIndex: "dealer_name",
          scopedSlots: {customRender: "dealerInfo"},
          title: "经销商信息"
        },
        {
          dataIndex: "servie_auth_status",
          scopedSlots: {customRender: "statusRender"},
          title: "授权状态"
        },
        // {
        //   dataIndex: 'servie_auth_status',
        //   scopedSlots: {customRender: 'authorServie'},
        //   title: '创作者平台',
        //   filterType: 'isShow'
        // },
        // {
        //   dataIndex: 'open_auth_status',
        //   scopedSlots: {customRender: 'authorOpen'},
        //   title: '互动数据',
        //   filterType: 'isShow'
        // },
        //  {
        //   dataIndex: 'group_name',
        //   title: '分组'
        // },
        {
          dataIndex: "data_update_time",
          title: "更新时间"
        },
        {title: "操作", align: "center", scopedSlots: {customRender: "operationAccount"}, width: 150}
      ],
      columnsLive: [
        {
          dataIndex: "nickname",
          title: "主播昵称"
        },
        {
          dataIndex: "dealer_name",
          title: "经销商名称"
        },
        {
          dataIndex: "platform",
          scopedSlots: {customRender: "livePlatform"},
          title: "平台",
          width: 100
        },
        {
          dataIndex: "code",
          title: "平台帐号"
        },
        {
          dataIndex: "title",
          scopedSlots: {customRender: "liveInfo"},
          title: "直播信息"
        },
        {
          dataIndex: "watch_count",
          scopedSlots: {customRender: "liveData"},
          title: "直播数据"
        },
        {
          dataIndex: "mtime",
          title: "更新时间"
        },
        {title: "操作", align: "center", scopedSlots: {customRender: "operationLive"}, width: 150}
      ],
      columnsVideo: [
        {
          scopedSlots: {customRender: "dataIndex"},
          title: "序号",
          width: 60
        },
        {
          dataIndex: "nickname",
          title: "主播昵称"
        },
        {
          dataIndex: "dealer_name",
          title: "经销商名称"
        },
        {
          dataIndex: "platform",
          scopedSlots: {customRender: "videoPlatform"},
          title: "平台",
          width: 100
        },
        {
          dataIndex: "code",
          title: "平台帐号"
        },
        {
          dataIndex: "title",
          scopedSlots: {customRender: "videoInfo"},
          title: "短视频信息"
        },
        {
          scopedSlots: {customRender: "videoDataInfo"},
          title: "短视频数据较昨日新增"
        },
        {
          dataIndex: "operation2",
          scopedSlots: {customRender: "videoData"},
          title: "短视频数据"
        },
        {
          dataIndex: "mtime",
          title: "更新时间"
        },
        {title: "操作", align: "center", scopedSlots: {customRender: "operation"}, width: 150}
      ],
      pagination: {
        total: 0,
        current: 1,
        page_size: 10
      },
      groupList: [],
      dataList: [],
      countData: {},
      searchFormData: {
        begin_time: moment().startOf("week").format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment().endOf("week").format("YYYY-MM-DD HH:mm:ss")
      },
      tabActive: "author",
      visible: false,
      dataForm: {
        begin_time: moment().subtract(7, "days").format("YYYY-MM-DD") + " 00:00:00",
        end_time: moment().subtract(1, "days").format("YYYY-MM-DD") + " 23:59:59",
        time: [moment().subtract(7, "days").format("YYYY-MM-DD") + " 00:00:00", moment().subtract(1, "days").format("YYYY-MM-DD") + " 23:59:59"]
      },
      data_layout: {
        labelCol: {span: 4},
        wrapperCol: {span: 20}
      },
      rules: {
        time: [
          {type: "array", required: true, message: "请选择时间", trigger: "change"}
        ]
      },
      is_submit: false,
      isLoading: false,
      dealersList: [],
      dealersData: {},
      activityList: [],
      KpiInfo: {},
      KPIList: [],
      searchData: {},
      TabList: [],
      TabColumns: [
        {
          dataIndex: "",
          scopedSlots: {customRender: "dealerInfo"},
          title: "经销商信息"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "accountInfo"},
          title: "风火轮帐号信息"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "sectionsInfo"},
          title: "课程学习进度"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "medalInfo"},
          title: "勋章获取情况"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "awardedInfo"},
          title: "认证情况"
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: {customRender: "operation"},
          width: 150
        }
      ],
      moduleTabColumns: [
        {
          dataIndex: "",
          scopedSlots: {customRender: "dealerInfo"},
          title: "经销商信息"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "statisticalInfo"},
          title: "统计平台"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "moduleInfo"},
          title: "模板信息"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "moduleTypeInfo"},
          title: "模板转化效果"
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: {customRender: "operation"},
          width: 150
        }
      ]
    };
  },
  created() {
    this.getGroupList();
    this.getDataList(this.searchFormData);
    this.getData(this.searchFormData);
    // this.getDataList(this.$refs[`${ val }Tab`].searchForm);
    // this.getActivityList();
    // this.getDealersList();
    // this.getKpiInfo();
  },
  methods: {
    onChangeTabs(val) {
      this.tabActive = val;
      switch(this.tabActive) {
        case "author":
          this.getDataList(this.$refs[`${ val }Tab`].searchForm);
          break;
        case "live":
          this.getDataList(this.$refs[`${ val }Tab`].searchForm);
          break;
        case "video":
          this.getDataList(this.$refs[`${ val }Tab`].searchForm);
          break;
        case "learning":
          this.getStudyList({});
          break;
        case "KPI":
          this.getKPIListList();
          break;
        case "module":
          this.getModuleListList({});
          break;
      }
    },
    getGroupList() {
      https.getGroupSelect({activity_id: this.activity_id}).then(res => {
        if(res.code === 200) {
          this.groupList = res.data || [];
        } else {
          this.groupList = [];
          console.log("获取分组失败");
        }
      });
    },
    getDataList(searchData) {
      console.log(searchData);
      this.isLoading = true;
      let type = "author";
      if(this.tabActive == "author") {
        searchData.begin_time = "";
        searchData.end_time = "";
        searchData.current = null;
        searchData.page_size = null;
      }
      // if(this.tabActive == "video") {
      //   searchData.current = null;
      //   searchData.page_size = null;
      // }
      let params_data = Object.assign(searchData, {
        activity_id: this.activity_id,
        principal_id: this.principal_id
      });
      if(this.tabActive === "author") {
        type = "author";
      } else if(this.tabActive === "live") {
        type = "live";
      } else {
        type = "aweme";
      }

      this.searchFormData = Object.assign(searchData, {
        activity_id: this.activity_id,
        principal_id: this.principal_id
      });

      let params = {
        type: type || "author",
        data: params_data
      };
      this.$api.core.TrainingList.getAccountList(params).then(res => {
        this.isLoading = false;
        if(res.code === 0) {
          let liveTableList = (res.data.list || []).map(v => _.merge(v, {__LOADING__: false}));
          this.dataList = liveTableList;
          this.pagination = res.data.pagination || {
            total: 0,
            current: 1,
            page_size: 10
          };
        } else {
          this.dataList = [];
          console.log("获取列表失败失败");
        }
      });
    },
    getData(searchData) {
      this.isLoading = true;
      let params_data = Object.assign(searchData, {
        activity_id: this.activity_id,
        principal_id: this.principal_id
      });
      this.searchFormData = Object.assign(searchData, {
        activity_id: this.activity_id,
        principal_id: this.principal_id
      });

      let params = {
        type: 1,
        data: params_data
      };
      this.$api.core.TrainingList.getData(params).then(res => {
        this.isLoading = false;
        if(res.code === 0) {
          this.countData = res.data || {};
        } else {
          console.log("获取经销商媒体统计失败");
        }
      });
    },
    onChange(searchData) {
      this.getDataList(searchData);
    },
    resetList(searchData) {
      this.getDataList(searchData);
      this.getData(searchData);
    },
    searchList(searchData) {
      this.getDataList(searchData);
      this.getData(searchData);
    },
    exportExcel(searchData) {
      let data = Object.assign(searchData, {
        activity_name: this.activity_name,
        activity_id: this.activity_id,
        principal_id: this.principal_id
      });

      let url = "/training/activities/author/export";
      if(this.tabActive === "author") {
        url = "/training/activities/author/export";
      } else if(this.tabActive === "live") {
        url = "/training/live/export";
      } else {
        url = "/training/aweme/export";
      }

      return uploadExcel(
          url,
          data,
          ""
      );
    },
    exportExcelAll() {
      this.$refs.dataForm.validate(async (valid, obj) => {
        if(valid) {
          this.handleCancel();
          try {
            const data = {
              begin_time: this.dataForm.begin_time,
              end_time: this.dataForm.end_time,
              activity_name: this.activity_name,
              activity_id: this.activity_id,
              principal_id: this.principal_id
            };

            await activityApi.exportAll(data)
          } catch (e) {
            console.error(e)
          }
        }
      });
      buryEvent("activity", "dowload-all", this.activity_name);
    },
    //打开时间弹窗
    handleOpen(type) {
      switch(type) {
        case 1:
          this.$router.push({
            name: "configurationTemplate",
            query: {
              activity_id: this.activity_id,
              activity_name: this.activity_name,
              principal_id: this.principal_id
            }
          });
          break;
        case 2:
          this.visible = true;
          buryEvent("activity", "open-all-data-popup", this.activity_name);
          break;
      }
    },
    //关闭时间弹窗
    handleCancel() {
      this.visible = false;
    },
    //日期选择框
    onChangeDate(dates) {
      let begin_time = "";
      let end_time = "";
      if(dates[0]) {
        begin_time = this.$moment(dates[0]._d).format("YYYY-MM-DD HH:mm:ss");
        end_time = this.$moment(dates[1]._d).format("YYYY-MM-DD HH:mm:ss");
      }
      this.dataForm.end_time = end_time;
      this.dataForm.begin_time = begin_time;
    },
    getActivityList() {
      let params = {
        activity_id: this.activity_id,
        principal_id: this.principal_id
      };
      https.getActivityList(params).then(res => {
        if(res.code === 0) {
          this.activityList = res.data || {};
        } else {
          this.$message.error(res.msg || "获取活动KPI详情失败");
        }
      });
    },
    getDealersList() {
      let params = {
        activity_id: this.activity_id
      };
      https.getDealersList(params).then(res => {
        if(res.code === 0) {
          this.dealersData = res.data || {};
          this.dealersList = res.data.dealer_kpis || [];
        } else {
          this.$message.error(res.msg || "获取经销商KPI详情失败");
        }
      });
    },
    getKPIListList() {
      let params = {
        activity_id: this.activity_id,
        kpi_name: this.searchData.kpi_name,
        start_time: this.searchData.start_time,
        end_time: this.searchData.end_time,
        current: this.pagination.current || this.searchData.current,
        page_size: this.pagination.page_size || this.searchData.page_size,
      };
      https.getKPIListList(params).then(res => {
        if(res.code === 0) {
          this.KPIList = res.data.data || [];
          this.pagination.total = res.data.total;
        } else {
          this.KPIList = [];
          this.$message.error(res.msg || "获取经销商KPI详情失败");
        }
      });
    },
    getStudyList(searchData) {
      this.isLoading = true;
      let params = {
        activity_id: this.activity_id,
        dealer_name: searchData.dealer_name,
        account_group_id: searchData.account_group_id,
        account: searchData.account,
        user_name: searchData.user_name,
        mobile: searchData.mobile,
        page_num: searchData.current ? searchData.current : this.pagination.current,
        page_size: searchData.page_size ? searchData.page_size : this.pagination.page_size,
      };
      https.getStudyList(params).then(res => {
        this.isLoading = false;
        if(res.code == 200) {
          this.TabList = res.data.list || [];
          this.pagination.total = res.data.total;
        } else {
          this.TabList = [];
          this.$message.error(res.message || "获取学情列表失败");
        }
      });
    },
    getKpiInfo() {
      let params = {
        activity_id: this.activity_id
      };
      https.getKpiInfo(params).then(res => {
        if(res.code === 0) {
          this.KpiInfo = res.data || {};
        } else {
          this.$message.error(res.msg || "获取KPI信息");
        }
      });
    },
    searchKPIList(searchData) {
      this.searchData = searchData;
      this.getKPIListList();
    },
    resetKPIList(resetData) {
      this.searchData = resetData;
      this.getKPIListList();
    },
    onChangePaginationKPIList(resetData) {
      this.searchData = resetData;
      this.getKPIListList();
    },
    searchTabList(searchData, type) {
      console.log(searchData, type);
      switch(type) {
        case "learning":
          this.getStudyList(searchData);
          break;
        case "module":
          this.getModuleListList(searchData);
          break;
      }
    },
    getModuleListList(searchData) {
      this.isLoading = true;
      let params = {
        activity_id: this.activity_id,
        dealer_name: searchData.dealer_name,
        platform_type: searchData.platform_type,
        start_time: searchData.start_time || moment().subtract(6, "days").format("YYYY-MM-DD") + " 00:00:00",
        end_time: searchData.end_time || moment().format("YYYY-MM-DD") + " 23:59:59",
        account_group_id: searchData.account_group_id,
        page_num: searchData.current ? searchData.current : this.pagination.current,
        page_size: searchData.page_size ? searchData.page_size : this.pagination.page_size,
      };
      https.getModuleListList(params).then(res => {
        this.isLoading = false;
        if(res.code == 200) {
          this.TabList = res.data.list || [];
          this.pagination.total = res.data.total;
        } else {
          this.TabList = [];
          this.$message.error(res.message || "获取模板列表失败");
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.top-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;

  .title {
    font-size: 24px;

  }
}

</style>
